.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.first-last-name-ticket-office {
  font-size: large;
}
.contact-requested {
width: 200px;
  margin-top: 10px;
}
.table-heading th {
  font-size: larger;
}
.main-ticket-office-table {

}
.event-date-ticket-office {
  display: inline-block;
  text-align: left;
  font-size: 16px;
  /* white-space: nowrap; */
  /* flex: 1; */
  
}
.bold-text {
  font-weight: 600;
}

.text-left {
  text-align: left;
}
.name-office-ticket-table{
  z-index: inherit;

}
.ticket-office-ticket-table{
  z-index: inherit;

}
.school-office-ticket-table{
  z-index: inherit;

}
.contact-info-office-ticket-table{
  z-index: inherit;

}
.ticket-status-office-ticket-table{
  z-index: inherit;

}
.record-status-office-ticket-table{
  z-index: inherit;

}
.submit-date-office-ticket-table{
  z-index: inherit;

}
.email-status-office-ticket-table{
  z-index: inherit;

}
.actions-office-ticket-table{
  z-index: inherit;

}

.search-section-ticket-office{
  display: flex;
  /* display: block; */
  /* float: right; */
}
.search-selection-ticket-office{
  flex: 1;
  /* width: 20%; not used */
  /* display: inline-block; */
}
.search-title-ticket-office{
  /* flex: 1; */
  float: left;
  margin: 0px 20px;
}
/* td {
  padding: 1vw;
} */

/* Default padding for larger screens */
/* td, th {
  padding: 20px;
} */

/* Adjust padding for medium screens */
/* @media (max-width: 768px) {
  td, th {
    padding: 15px;
  }
} */

/* Further reduce padding for small screens */
/* @media (max-width: 480px) {
  td, th {
    padding: 10px;
  }
} */
