/* h1 {
    font-size: 3rem;
    color: #000000;
} */

.logout-button {
    position: absolute;
    top: 20px;
    right: 20px;
}

  
.button-container {
    display: flex;
    /* flex: 1 1 20%;  */
    gap: 5px; /* Spacing around buttons */
    margin: 10px;
    padding: 10px 15px; /* Button padding, adjust for bigger/smaller buttons */
    text-align: center;
    justify-content: space-around;
  }

.toggle-children {
  flex: 1;
  flex-direction: column;
}

.tab-container {
  display: flex;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 2.5s forwards;
}
