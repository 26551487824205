/* .fixed-header {
    overflow-y: auto;
    position: fixed;
    top: 350px;
    z-index: 0;
} */

.custom-datepicker-popper {
    z-index: 2000 !important; /* Ensure it is high enough and add !important to override other styles */
    position: relative; /* Ensure it can be positioned correctly */
}


/* .table-heading {
    z-index: inherit;
} */

.scrollable-table {
    /* table-layout: auto;
    width: 100%; */
    max-height: calc(100vh - 100px);
    overflow-y: auto;
}

th {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1; /* This should be lower than the datepicker popper */
}

/* Optional: Add a box shadow or border to the sticky header for better separation */
th {
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.mentor-search-dropdown .dropdown-item {
    padding: 5px 10px;
    font-size: 20px;
    color: #000000dc;
    background-color: rgb(255, 255, 255)
  }
  
  .mentor-search-dropdown .dropdown-item:hover {
    background-color: #f7f2f2;
  }

  .custom-textbox-width .form-control {
    width: 100%; /* Or any specific width */
    max-width: 500px;
    height: 200px;
    word-wrap: break-word;

    
}
.table td, .table th {
    max-width: 450px;
    word-wrap: break-word;
}

.ticket-info-container {
    display: flex;
    align-items: center;
}

.edit-ticket-button {
    margin-right: 8px;
}

.ticket-notes-button {
    margin-right: 8px;
}
.ticket-notes-container {
    display: flex;
    align-items: center;
    max-width: 600px; /* Adjust as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.ticket-notes-field-mod {
    
}

.ticket-email-field-mod .form-check {
    display: flex;
    align-items: left;
}
.ticket-email-field-mod .form-check-input {
    margin-right: 10px; /* Adjust space between checkbox and label as needed */
    margin-top: 0; /* Align checkbox vertically centered if needed */
}

.icon-true {
    color: green;
}

.icon-false {
    color: red;
}

.left-aligned-checkbox {
    display: block; /* Ensures that the form group takes up the full width */
    text-align: left; /* Aligns the content to the left */
}

.left-aligned-checkbox .form-check {
    display: inline-flex; /* Keeps the checkbox and label inline */
    align-items: center; /* Vertically centers the checkbox with the label */
    width: auto; /* Ensures the form check doesn't stretch out */
}
.search-container {
    display: flex;
    background-color: lightblue;
}
.search-bar-ticket-office{
    flex: 1;
    /* width: 50%; */
    /* color: red; */
}

.event-dates-container {
    padding-left: 0;  /* Removes padding to the left*/
    list-style: none;  /* Removes bullet points*/
    margin: 0;  /* Removes default margin*/ 
    text-align: center;
    border-radius: 10px;
}

.event-dates-list{
    list-style: none;
    border: 1px solid black;
    text-align: center;
    margin-top: 3px;
    border-radius: 10px;
    transition: 100ms;
}

.event-dates-list:hover {
    background-color: rgb(170, 170, 170);
    /* font-weight: bold; */
}
.make-event-button {
    margin-left: 8px;
}
          
.management-button-list{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.modal-footer-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.modal-footer Button{
    flex: 1;
}

.modal-header {
    font-size: larger;
    font-weight: bold;
}
#end_time {
    width: auto;
}
#start_time {
    width: auto;
}
#date {
    width: auto;
}

.mentor-list {
    font-weight: bold;
}
.mentor-content-box {
    text-align: left;
}

/* --------------- */
.teacher-content-box {
    display: flex;
    flex-direction: column;
}

.teacher-info-container {
    display: flex;
    flex-direction: column;
    margin: 0px; 
}

.teacher-contact-info-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* margin: 5px; */
}

.teacher-contact-info {
    font-weight: bold;
    min-width: 80px; /* Ensure that all labels have the same width */
}


.classroom-info-container {
    display: flex;
    /* flex-direction: row; */
    
    
}

.classroom-content-box {
    text-align: left;
}
.classroom-info-list {
    font-weight: bold;
    /* text-align: left; */
    min-width: 150px;
}

.teacher-status-container{
    display: flex;
    justify-content: flex-start;
}

.teacher-status-info{
    font-weight: bold;
    min-width: 80px;
}

.teacher-contact-info, .teacher-status-info, .classroom-info-list {
    display: block; /* Ensures each piece of info starts on a new line */
}

.teacher-contact-info-container, .teacher-status-container {
    margin-bottom: 10px; /* Spacing within the teacher info itself */
}

/* -------------------- */
.map-pin{
    background-color: lightgray;
    color: green;
    border-radius: 20%;
    /* margin-left: 5px; */
    /* display: inline-block; */
    float: inline-end;
    padding: 10px;
}
.map-pin:hover{
    background-color: gray;
}
.mentor-events {
    display: flex;
    overflow-y: auto;

/* 
    flex: 1 1 33%;
    display: flex;
    flex-wrap: nowrap; */
    justify-content: space-between;
}

.mentor-events-visits {
    display: flex;
    min-width: 200px;
    overflow-y: auto;
    max-height: 300px;
    
}

.mentor-event-item {
    flex: auto;
    margin: 10px;
    display: flex;
    flex-direction: column;
}

.mentor-event-item div, .mentor-event-item Button {
    margin-top: 10px; 
}

.user-info-container{
    display: flex;
    flex-direction: column;
}

.user-info-list{
    text-align: start;
}

.user-info-content {
    font-weight: bold;
    text-align: left;
}

.classroom-status-list {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    gap: 10px; /* Adds space between items */
    margin: 0; /* Removes default margin */
    padding: 0; /* Removes default padding */
    max-height: 300px; /* Adjust this value based on your needs */
    max-width: 650px;
    overflow-y: auto; /* Enables vertical scrolling */
    border: 1px solid #ccc; /* Optional: Adds a border around the container */
    padding: 10px; /* Optional: Adds some padding inside the container */
}

.classroom-status-item {
    text-align: left;
    flex: 1; /* Allows items to grow to fill available space */
    min-width: 150px; /* Minimum width for each item to ensure readability */
    max-width: 300px;
    margin: 5px; /* Adds some space around each item */
    padding: 5px; /* Adds padding inside each item for better spacing of content */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: Adds a subtle shadow for depth */
    border-radius: 4px; /* Optional: Rounds the corners of each item */
    background-color: #f9f9f9; /* Optional: Adds a background color to each item */
}

.form-group-flex .label-input-pair {
    display: flex;
    align-items: center; /* Align items vertically in the center */
    justify-content: space-between; /* Space between label and input */
}

.form-group-flex .label-input-pair .form-label {
flex: 2; /* Allows the label to grow and take up space */
}

.form-group-flex .label-input-pair .form-control {
flex: 1; /* Allows the input to grow less than the label */
}

.classroom-status-label{
font-weight: bold;
}

.ticket-office-form-labels {
font-weight: bold;
}

.ticket-office-main-ticket-contact-status{
max-width: 400px;
}

.cactus-verification-hover {
display: flex;
align-items: center;
background-color: whitesmoke;
border: 1px solid black;
border-radius: 4px;
margin: 5px;
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.cactus-verification-hover:hover {
background-color: grey;
border: 1px solid black;
}
